/**
 *  Do not remove this comments bellow. It's the markers used by wiredep to inject
 *  sass dependencies when defined in the bower.json of your dependencies
 */
// bower:scss
// endbower

/**
 *  Do not remove this comments bellow. It's the markers used by gulp-inject to inject
 *  all your sass files automatically
 */
// injector
@import "external/finan/main.scss";
@import "external/fuse/style.scss";
@import "external/w3/style.scss";
@import "external/finan/scss/helpers.scss";
@import "external/finan/scss/style.scss";
@import "external/fuse/cities/cities.scss";
@import "external/fuse/profile/profile.scss";
@import "external/fuse/report/report.scss";
@import "external/fuse/scss/components.scss";
@import "external/fuse/scss/mixins.scss";
@import "external/fuse/scss/rapi-page.scss";
@import "external/fuse/user/user.scss";
@import "external/fuse/xsession/xsession.scss";
@import "external/fuse-core/scss/global.scss";
@import "external/fuse-core/theme-options/theme-options.scss";
@import "external/finan/main/dashboards/dashboard-project.scss";
@import "external/finan/main/lancamento/lancamento.scss";
@import "external/finan/main/programar/programar.scss";
@import "external/finan/main/relatorios/relatorios.scss";
@import "external/finan/main/transferencia/transferencia.scss";
@import "external/fuse/acl/role/role.scss";
@import "external/fuse/auth/forgot-password/forgot-password.scss";
@import "external/fuse/auth/login-v2/login-v2.scss";
@import "external/fuse/auth/reset-password/reset-password.scss";
@import "external/fuse/components/rf-activities/activities.scss";
@import "external/fuse/components/rf-avatar/rf-avatar.scss";
@import "external/fuse/components/rf-comments/comments.scss";
@import "external/fuse/components/rf-input-city/rf-input-city.scss";
@import "external/fuse-core/directives/ms-card/ms-card.scss";
@import "external/fuse-core/directives/ms-form-wizard/ms-form-wizard.scss";
@import "external/fuse-core/directives/ms-info-bar/ms-info-bar.scss";
@import "external/fuse-core/directives/ms-masonry/ms-masonry.scss";
@import "external/fuse-core/directives/ms-material-color-picker/ms-material-color-picker.scss";
@import "external/fuse-core/directives/ms-nav/ms-nav.scss";
@import "external/fuse-core/directives/ms-navigation/ms-navigation.scss";
@import "external/fuse-core/directives/ms-responsive-table/ms-responsive-table.scss";
@import "external/fuse-core/directives/ms-scroll/ms-scroll.scss";
@import "external/fuse-core/directives/ms-search-bar/ms-search-bar.scss";
@import "external/fuse-core/directives/ms-shortcuts/ms-shortcuts.scss";
@import "external/fuse-core/directives/ms-splash-screen/ms-splash-screen.scss";
@import "external/fuse-core/directives/ms-stepper/ms-stepper.scss";
@import "external/fuse-core/directives/ms-timeline/ms-timeline.scss";
@import "external/fuse-core/directives/ms-widget/ms-widget.scss";
@import "external/fuse-core/scss/partials/angular-material-extend.scss";
@import "external/fuse-core/scss/partials/animations.scss";
@import "external/fuse-core/scss/partials/colors.scss";
@import "external/fuse-core/scss/partials/helpers.scss";
@import "external/fuse-core/scss/partials/icons.scss";
@import "external/fuse-core/scss/partials/layouts-page.scss";
@import "external/fuse-core/scss/partials/layouts-template.scss";
@import "external/fuse-core/scss/partials/material.scss";
@import "external/fuse-core/scss/partials/mixins.scss";
@import "external/fuse-core/scss/partials/print.scss";
@import "external/fuse-core/scss/partials/reset.scss";
@import "external/fuse-core/scss/partials/typography.scss";
@import "external/fuse-core/scss/partials/variables.scss";
@import "external/w3/components/w3-activities/activities.scss";
@import "external/w3/components/w3-comments/comments.scss";
@import "external/w3/components/w3-input-city/w3-input-city.scss";
@import "external/w3/components/w3-report-date/w3-report-date.scss";
@import "external/w3/components/w3-uploud/uploud.scss";
@import "external/finan/main/lancamento/sidenavs/saldo-sidenav.scss";
@import "external/fuse/layouts/partials/navigation/navigation.scss";
@import "external/fuse/layouts/partials/quickpanel/quickpanel.scss";
@import "external/fuse/layouts/partials/toolbar/toolbar.scss";
@import "external/fuse-core/scss/partials/plugins/angular-google-map.scss";
@import "external/fuse-core/scss/partials/plugins/angular-moment-picker.scss";
@import "external/fuse-core/scss/partials/plugins/c3.scss";
@import "external/fuse-core/scss/partials/plugins/chartist.scss";
@import "external/fuse-core/scss/partials/plugins/chartjs.scss";
@import "external/fuse-core/scss/partials/plugins/datatable.scss";
@import "external/fuse-core/scss/partials/plugins/highlight.scss";
@import "external/fuse-core/scss/partials/plugins/nvd3.scss";
@import "external/fuse-core/scss/partials/plugins/perfect-scrollbar.scss";
@import "external/fuse-core/scss/partials/plugins/text-angular.scss";
@import "external/finan/main/programar/dialogs/programar/programar-dialog.scss";
@import "external/fuse-core/directives/ms-card/templates/template-10/template-10.scss";
@import "external/fuse-core/directives/ms-card/templates/template-1/template-1.scss";
@import "external/fuse-core/directives/ms-card/templates/template-2/template-2.scss";
@import "external/fuse-core/directives/ms-card/templates/template-3/template-3.scss";
@import "external/fuse-core/directives/ms-card/templates/template-4/template-4.scss";
@import "external/fuse-core/directives/ms-card/templates/template-5/template-5.scss";
@import "external/fuse-core/directives/ms-card/templates/template-6/template-6.scss";
@import "external/fuse-core/directives/ms-card/templates/template-7/template-7.scss";
@import "external/fuse-core/directives/ms-card/templates/template-9/template-9.scss";
@import "external/fuse-core/directives/ms-card/templates/template-8/template-8.scss";
@import "external/fuse-core/directives/ms-stepper/templates/horizontal/horizontal.scss";
@import "external/fuse-core/directives/ms-stepper/templates/vertical/vertical.scss";
// endinjector

$dark-color: #261b62;
$secondary-color : #FC6E00;
#vertical-navigation .navigation-header{
        background-color: rgb(255, 255, 255);
}
#vertical-navigation .navigation-header .fold-toggle{
    color:$dark-color;
}

#vertical-navigation {
     background-color: $dark-color!important;
}

.ms-navigation .ms-navigation-node .ms-navigation-item > .ms-navigation-button.active{
    background-color: $secondary-color;
}

#splash-screen {
  .logo {
    background-color: $secondary-color;
    color: #fff;
    font-weight: bold;
    border-radius: 4px;
  }
}